import React from 'react'
import PropTypes from 'prop-types';
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

function ArticleItem({title, uri, date, excerpt, coverPicture}) {
  return (
    <article className="mb-8">
      {coverPicture !== null && (
        <Link to={uri}>
          <GatsbyImage
            alt={`${title} Cover`}
            image={getImage(coverPicture)}
            className="w-full mb-4"
          />
        </Link>
      )}
      <time className="block mb-2 text-xs tracking-widest uppercase">{date}</time>
      <h3 className="mb-3 text-xl font-bold underline text-blue-800 dark:text-blue-400"><Link to={uri}>{title}</Link></h3>
      <p>{excerpt}</p>
    </article>
  )
}


ArticleItem.propTypes = {
  title: PropTypes.string,
  uri: PropTypes.string,
  date: PropTypes.string,
  excerpt: PropTypes.string,
  coverPicture: PropTypes.any,
};

export default ArticleItem
