import React from "react";
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../components/layout";
import SEO from "../components/seo";
import ArticleItem from "../components/articleItem";

function PostsPage({ data }) {
  const { posts } = data

  const latestPosts = posts && posts.edges.filter(({ node }) => new Date() >= new Date(node.fields.date))

  return (
    <Layout>
    <SEO
      keywords="rodrigo, passos, rodrigopassos, javascript, craftcms"
      title="Writings"
      description="Thougthts on code, life and everything in between."
    />

      <section className="max-w-4xl px-3 mx-auto md:px-6 dark:text-gray-200">
        <h1 className="mb-3 text-4xl font-bold">
          Writings
        </h1>
        <p>Thougthts on code, life and everything in between.</p>
      </section>

      <section className="max-w-4xl px-3 mx-auto md:px-6 mt-2 dark:text-gray-200">
        {latestPosts && latestPosts.map( ({ node }, index) => (
          <ArticleItem
            key={`article-${index}`}
            title={node.frontmatter.title}
            uri={node.fields.uri}
            date={node.fields.date}
            excerpt={node.frontmatter.excerpt !== undefined && node.frontmatter.excerpt && node.frontmatter.excerpt.length > 0 ? node.frontmatter.excerpt : node.excerpt}
            coverPicture={node.frontmatter.coverPicture}
          />
        ))}
      </section>
    </Layout>
  );
}

PostsPage.propTypes = {
  data: PropTypes.object,
};

export default PostsPage;

export const query = graphql`
  query LatestPostsQuery {
    posts: allMarkdownRemark(
      filter: { fields: {slug: { ne: null }}, frontmatter: {draft: {ne: true}} }
      sort: { fields: [fields___prefix], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
            date: prefix(formatString: "MMMM DD, YYYY")
            uri
          }
          frontmatter {
            title
            excerpt
            coverPicture {
              childImageSharp {
                gatsbyImageData(width: 960)
              }
            }
          }
        }
      }
    }
  }
`